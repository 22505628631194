var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('section',[_c('div',{staticClass:"modal-header"},[(_vm.pageType === 'edit')?_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.l("Edit"))+": "+_vm._s(_vm.organizationUnit.name)+" ")],1):_c('div',{staticClass:"modal-title"},[_c('a-icon',{attrs:{"type":"share-alt"}}),_vm._v(_vm._s(_vm.pageType === "create" ? "创建" : "查看")+"：印章 ")],1)]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'name',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'number',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'number',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'status',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'status',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"是否默认","required":""}},[_c('a-switch',{attrs:{"disabled":_vm.isDisabled},model:{value:(_vm.switchVal),callback:function ($$v) {_vm.switchVal=$$v},expression:"switchVal"}})],1)],1)],1),_c('a-row',{staticClass:"form-wrapper",attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'sort',
              {
                rules: [
                  { required: true, message: _vm.l('ThisFieldIsRequired') },
                  { max: 128, message: _vm.l('MaxLength') } ],
              } ]),expression:"[\n              'sort',\n              {\n                rules: [\n                  { required: true, message: l('ThisFieldIsRequired') },\n                  { max: 128, message: l('MaxLength') },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"请输入","allowClear":"","disabled":_vm.isDisabled}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"附件资源","required":""}},[_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.fileList,"showUploadList":{
              showPreviewIcon: true, showRemoveIcon: !_vm.isDisabled 
            }},on:{"change":_vm.handleUploadChange}},[(!_vm.isDisabled)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e()],1)],1)],1)],1)],1),(_vm.pageType !== 'preview')?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_c('a-icon',{attrs:{"type":"close-circle"}}),_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")],1),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.handleSubmit()}}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.l("Save"))+" ")],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }