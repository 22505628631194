<template>
  <a-spin :spinning="spinning">
    <div class="user-container">
      <div class="table--container">
        <!-- 操作 -->
        <a-row
          :gutter="8"
          class="opeattion-container"
          type="flex"
          align="middle"
          justify="space-between"
        >
          <a-col :span="16">
            <a-input-search
              name="filterText"
              @search="getData(reqUrl.getPaged, id)"
              :placeholder="l('SearchWithThreeDot')"
              enterButton
              v-model="name"
            />
          </a-col>
          <a-col :span="6" :offset="2">
            <a @click="createOrEdit(null, 'create')">
              <a-icon type="plus" /> {{ l("添加印章") }}
            </a>
            <a-divider type="vertical"></a-divider>
            <a :title="l('Refresh')" @click="clearFilterAndRefresh()">
              <a-icon type="reload"
            /></a>
          </a-col>
        </a-row>
        <!-- table -->
        <list-table
          :table-data="tableData"
          :total-items="totalItems"
          :tableKey="tableKey"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :columns="columns"
          @emitPreview="previewItem"
          @emitEdit="editItem"
          @emitDelete="deleteItem"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
        </list-table>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitsServiceProxy } from "./services/organization-units-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import { ListTable } from "@/components";
import AddCustomerSealComponent from "./add-customer-seal/add-customer-seal";
import AddOrganizeSealComponent from "./add-organize-seal/add-organize-seal";

export default {
  name: "organization-unit-seal-panel",
  mixins: [AppComponentBase],
  props: ["id", "reqUrl", "tableKey"],
  components: { ListTable },
  data() {
    return {
      spinning: false,
      sealService: null,
      selectedRowKeys: [],
      filteredInfo: null,
      sortedInfo: null,
      name: "",
      organizationUnitId: "",
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 30, skipCount: 0 },
      // 用户表格
      columns: [
        // {
        //   title: this.l("所属组织机构"),
        //   dataIndex: "displayName",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "displayName" },
        // },
        {
          title: this.l("名称"),
          dataIndex: "name",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.l("编号"),
          dataIndex: "number",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "number" },
        },
        // {
        //   title: this.l("图片"),
        //   dataIndex: "pictureUrls",
        //   sorter: true,
        //   align: "center",
        //   scopedSlots: { customRender: "pictureUrls" },
        // },
        {
          title: this.l("状态"),
          dataIndex: "statusStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "statusStr" },
        },
        {
          title: this.l("是否默认"),
          dataIndex: "isDefaultStr",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "isDefaultStr" },
        },
        {
          title: this.l("排序值"),
          dataIndex: "sort",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "sort" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      actionsType: [],
      idKey: '', // 调用getPaged时 id的key
    };
  },
  computed: {},
  created() {
    this.sealService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.initActionsType()
    this.idKey = this.tableKey === 'CustomerSealPagedDto' ? 'customerId' : 'organizationUnitId'
  },
  beforeDestroy() {},
  methods: {
    // 拉取数据
    async getData(url, id) {
      this.spinning = true;
      try {
        let res = await this.sealService.getSealPaged(
          url ? url : this.reqUrl.getPaged,
          this.name,
          this.idKey,
          id ? id : this.id,
          this.filterText,
          this.request.sorting,
          this.request.maxResultCount,
          this.request.skipCount
        );
        this.spinning = false;
        this.tableData = res.items.map((o) => {
          return {
            ...o,
            isDefaultStr: o.isDefault ? "是" : "否",
            statusStr: o.status ? "正常" : "禁用",
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
        // console.log(res);
      } catch (error) {
        this.spinning = false;
      }
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData(this.reqUrl.getPaged);
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 删除
    async deleteItem(newV) {
      let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.sealService.deleteSeal(id, this.reqUrl.delete);
        this.spinning = false;
        this.getData(this.reqUrl.getPaged);
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        this.spinning = false;
      }
    },
    // 预览
    previewItem(newV) {
      this.createOrEdit(newV.item, 'preview');
    },
    // 修改
    editItem(newV) {
      // let { id } = newV.item;
      this.createOrEdit(newV.item, 'edit');
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData(this.reqUrl.getPaged);
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData(this.reqUrl.getPaged);
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData(this.reqUrl.getPaged);
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    // 初始化需要显示的func btn
    initActionsType() {
      let obj = {
        type: "unit-seal",
        isShow: true,
        fns: {
          check: { granted: true, name: this.l("查看") },
          edit: { granted: true, name: this.l("Edit") },
          create: { granted: false, name: "" },
          delete: { granted: true, name: this.l("Delete") },
        },
      };
      this.actionsType = obj;
    },
    // 增加修改查看
    createOrEdit(item, type) {
      let comp = this.tableKey === 'CustomerSealPagedDto' ? AddCustomerSealComponent : AddOrganizeSealComponent
      ModalHelper.create(
        comp,
        {
          organizationUnit: {
            type: type, // edit,preview,create
            parentId: this.id,
            id: item ? item.id : null,
            reqUrl: this.reqUrl,
          },
        },
        { 
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.user-container {
  border: 1px solid #e8e8e8;
  margin: 20px;
  padding: 20px;
  .table-edit,
  .table-delete {
    margin-left: 3px;
    i {
      padding: 0 2px;
    }
  }
}
.opeattion-container {
  margin: 20px 0;
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
