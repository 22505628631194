<template>
  <section>
    <div class="modal-header">
      <div class="modal-title" v-if="pageType === 'edit'">
        <a-icon type="share-alt" />{{ l("Edit") }}:
        {{ organizationUnit.name }}
      </div>
      <div class="modal-title" v-else>
        <a-icon type="share-alt" />{{
          pageType === "create" ? "创建" : "查看"
        }}：账户
      </div>
    </div>
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="开户行">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="账户">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'account',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="分行名称">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'branchName',
                {
                  rules: [
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否默认">
            <a-switch :disabled="isDisabled" v-model="switchVal" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="状态">
            <a-select
              placeholder="请输入"
              :disabled="isDisabled"
              v-decorator="[
                'status',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') }
                  ],
                },
              ]"
            >
              <a-select-option
                :value="item.value"
                v-for="item in StatusData"
                :key="item.value"
              >
                {{ item.key }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
        </a-col>
      </a-row>
    </a-form>

    <div class="modal-footer" v-if="pageType !== 'preview'">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </section>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { OrganizationUnitsServiceProxy } from "@/app/admin/organization-units/services/organization-units-proxies";
import { environment } from "@/environments/environment";
import { ProjectServiceProxy } from "@/shared/service-proxies";

export default {
  name: "add-seal",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      accountService: null,
      formDate: null,
      pageType: "", // preview,edit,create
      isDisabled: false,
      switchVal: false,
      Proxy: null,
      StatusData: []
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.accountService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.Proxy = new ProjectServiceProxy(this.$apiUrl,this.$api);
    this.GetStatusType();
  },
  mounted() {
    // 修改
    if (this.organizationUnit) {
      // this._getOrganizationExtendById(this.organizationUnit.id)
      this.$nextTick(() => {
        this.form.setFieldsValue({
          // id: this.organizationUnit.id,
          // organizationUnitId: this.organizationUnit.organizationUnitId,
          name: this.organizationUnit.name,
          branchName: this.organizationUnit.branchName,
          account: this.organizationUnit.account,
          status: this.organizationUnit.status,
          // isDefault: this.organizationUnit.isDefault,
        });
      });
      this.switchVal = this.organizationUnit.isDefault === true
      this.pageType = this.organizationUnit.type;
      this.isDisabled = this.organizationUnit.type === "preview";
    }
  },
  methods: {
    async _getOrganizationExtendById(id) {
      let res = await this.accountService.getOrganizationExtendById(id);
    },
    // 提交表单
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.createEditUnit(values);
        }
      });
    },
    // 创建
    async createEditUnit(params) {
      //   Object.assign(params, {
      //     parentId: this.organizationUnit.parentId,
      //   });
      this.saving = true;
      try {
        let res = await this.accountService.saveOrganizationBankAccount({
          ...params,
          organizationUnitId: this.organizationUnit.organizationUnitId,
          id: this.organizationUnit.id ? this.organizationUnit.id : null,
          isDefault: this.switchVal,
        });
        this.saving = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this.success(res);
      } catch (error) {
        this.saving = false;
      }
    },
    GetStatusType () {
      this.Proxy.getEnumKeyValueList(3).then((res) => {
        if (res && res.length > 0) {
          let array = res;
          for (let i = 0; i < array.length; i++) {
            let item = array[i];
            this.StatusData.push(item);
          }
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
    }
  }
}
</style>
