<template>
  <a-spin :spinning="spinning">
  <section>
    <div class="modal-header">
      <div class="modal-title" v-if="pageType === 'edit'">
        <a-icon type="share-alt" />{{ l("Edit") }}:
        {{ organizationUnit.name }}
      </div>
      <div class="modal-title" v-else>
        <a-icon type="share-alt" />{{
          pageType === "create" ? "创建" : "查看"
        }}：印章
      </div>
    </div>
    <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="名称">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'name',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="编号">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'number',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="状态">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'status',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="是否默认" required>
            <a-switch :disabled="isDisabled" v-model="switchVal" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="24" class="form-wrapper">
        <a-col :span="12">
          <a-form-item label="排序">
            <a-input
              placeholder="请输入"
              allowClear
              :disabled="isDisabled"
              v-decorator="[
                'sort',
                {
                  rules: [
                    { required: true, message: l('ThisFieldIsRequired') },
                    { max: 128, message: l('MaxLength') },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="附件资源" required >
            <a-upload
              :action="uploadUrl"
              :multiple="true"
              :data="{ subdir: this.subdirName }"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true, showRemoveIcon: !isDisabled 
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled"> <a-icon type="upload" /> 上传附件 </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="modal-footer" v-if="pageType !== 'preview'">
      <a-button :disabled="saving" @click="close()" type="button">
        <a-icon type="close-circle" />
        {{ l("Cancel") }}
      </a-button>
      <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
        <a-icon type="save" />
        {{ l("Save") }}
      </a-button>
    </div>
  </section>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import { OrganizationUnitsServiceProxy } from "../services/organization-units-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  name: "add-customer-seal",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      sealService: null,
      formDate: null,
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      pageType: "", // preview,edit,create
      isDisabled: false,
      switchVal: false,
    };
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this.sealService = new OrganizationUnitsServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
    this.pageType = this.organizationUnit.type;
    this.isDisabled = this.organizationUnit.type === "preview";
  },
  mounted() {
    // 修改
    if (this.organizationUnit.id) {
      this.$nextTick(() => {
        this._getSealById(this.organizationUnit.id, this.organizationUnit.reqUrl.getItem)
      });
    }
  },
  methods: {
    async _getSealById(id, url) {
      this.spinning = true
      try {
        let res = await this.sealService.getSealById(id, url);
        this.form.setFieldsValue({
          ...res,
          status: res.status.toString()
        });
        this.switchVal = res.isDefault === true
        if (res.pictureUrls && res.pictureUrls.indexOf(",") !== -1) {
          let arr = res.pictureUrls.split(",");
          arr.map((item, i) => {
            let n = item.split("/");
            this.fileList.push({
              uid: `-${i + 1}`,
              url: `${AppConsts.uploadBaseUrl}${item}`,
              name: n[n.length - 1],
              response: {data: {Path: item}},
              status: 'done',
            });
          });
        } else if (res.pictureUrls) {
          let n = res.pictureUrls.split("/");
          this.fileList.push({
            uid: `-1`,
            url: `${AppConsts.uploadBaseUrl}${res.pictureUrls}`,
            name: n[n.length - 1],
            response: {data: {Path: res.pictureUrls}},
            status: 'done',
          });
        }
        this.spinning = false
      } catch (error) {
        this.spinning = false
      }
    },
    // 上传组件 @change
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      console.log(this.fileList);
    },
    // 提交表单
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          this.createEditUnit(values);
        }
      });
    },
    // 创建
    async createEditUnit(params) {
      if (!this.fileList.length) {
        this.$message.info('请上传附件资源')
        return
      }
      //   Object.assign(params, {
      //     parentId: this.organizationUnit.parentId,
      //   });
      this.saving = true;
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });

      let pictureUrls =
        fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;

      try {
        let res = await this.sealService.saveSeal({
          ...params,
          customerId: this.organizationUnit.parentId,
          id: this.organizationUnit.id ? this.organizationUnit.id : null,
          isDefault: this.switchVal,
          pictureUrls: pictureUrls,
        }, this.organizationUnit.reqUrl.save);
        this.saving = false;
        this.$notification["success"]({
          message: this.l("SavedSuccessfully"),
        });
        this.success(res);
      } catch (error) {
        this.saving = false;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}
.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
  .preview-list {
    margin-bottom: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 30px;
    }
  }
}
</style>
