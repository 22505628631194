<template>
  <a-spin :spinning="spinning">
    <div class="modal-header">
      <div class="modal-title">
        <a-icon type="medicine-box" />
        <span span v-if="customer.id"
          >{{ customer.type === "edit" ? "修改" : "查看" }}{{ customer.shortName }}</span
        >
        <span span v-if="!customer.id">创建线下合同</span>
      </div>
    </div>
    <!-- tab切换 -->
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <!-- 角色名称 -->
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="medicine-box" />
          {{ l("基本信息") }}
        </span>
        <edit
          @close="close"
          @success="success"
          @emitSpinning="updateSpinning"
          :id="customer.id ? customer.id : null"
          :type="customer.type"
        ></edit>
      </a-tab-pane>
    </a-tabs>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalComponentBase } from "@/shared/component-base";
import Bus from "@/shared/bus/bus";
import Edit from "../edit";
import OrganizationUnitSealPanel from "@/components/organization-unit-seal-panel/organization-unit-seal-panel";
import OrganizationUnitAccountPanel from "@/components/organization-unit-account-panel/organization-unit-account-panel";

export default {
  name: "create-edit",
  mixins: [AppComponentBase, ModalComponentBase],
  data() {
    return {
      spinning: false,
      // 表单
      formLayout: "horizontal",
      // 选中的权限过滤
      selectedPermission: [],
      role: {},
      activeKey: -1,
      sealUrl: {
        getPaged: "Customer/GetCustomerSealPaged",
        save: "Customer/SaveCustomerSeal",
        getItem: "Customer/GetCustomerSealById",
        delete: "Customer/DeleteCustomerSeal",
      },
      accountUrl: {
        getPaged: "Customer/GetCustomerBankAccountPaged",
        save: "Customer/SaveCustomerBankAccount",
        getItem: "Customer/GetCustomerBankAccountById",
        delete: "Customer/DeleteCustomerBankAccount",
      },
    };
  },
  components: {
    Edit,
    OrganizationUnitSealPanel,
    OrganizationUnitAccountPanel,
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  methods: {
    updateSpinning(newV) {
      if (newV === "0") {
        this.spinning = false;
      } else {
        this.spinning = true;
      }
    },
    tabChange(activeKey) {
      this.activeKey = activeKey;
      switch (activeKey) {
        case "1":
          // do sth...
          break;
        case "2":
          this.$refs.seal.getData(this.sealUrl.getPaged);
          break;
        case "3":
          this.$refs.account.getData(this.accountUrl.getPaged);
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.modal-header {
  margin-bottom: 0;
  .anticon {
    margin-right: 10px;
  }
}
.pagination {
  margin: 10px auto;
  text-align: right;
}
</style>
