<template>
  <div>
    <!-- <div class="modal-header">
        <div class="modal-title">
            <span v-if="id">{{ l("Edit") }}</span>
            <span v-if="!id">{{ l("Create") }}</span>
        </div>
    </div> -->
    <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :form="form">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item :label="l('签约客户')">
            <a-select
              show-search
              :disabled="type=='edit'?true:isDisabled"
              :placeholder="l('请选择')"
              :filter-option="filterOption"
              :options="customerOption"
              v-decorator="[
                'customerId',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('签约公司')">
            <a-select
              show-search
              :disabled="type=='edit'?true:isDisabled"
              :placeholder="l('请选择')"
              :filter-option="filterOption"
              :options="orgOption"
              v-decorator="[
                'organizationUnitId',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('合同名称')">
            <a-input
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'contractName',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('合同编码')">
            <a-input
              :placeholder="l('请输入')"
               v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('合同类型')">
            <a-select
              :disabled="isDisabled"
              :placeholder="l('请选择')"
              v-decorator="[
                'contractType',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            >
              <a-select-option :value="1"> 人力外包合同 </a-select-option>
              <a-select-option :value="2"> 咨询服务合同 </a-select-option>
              <a-select-option :value="3"> 融资租赁合同 </a-select-option>
              <a-select-option :value="4"> 技术合同 </a-select-option>
              <a-select-option :value="5"> 新经济个体 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('签署日期')">
            <a-date-picker
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'dateOfSigning',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('起始时间')">
            <a-date-picker
              style="width: 100%"
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'startTime',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item :label="l('结束时间')">
            <a-date-picker
              style="width: 100%"
              :disabled="isDisabled"
              :placeholder="l('请输入')"
              v-decorator="[
                'endTime',
                {
                  rules: [
                    {
                      required: true,
                      message: l('validation.required'),
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item :label="l('附件资源')">
            <a-upload
              :action="uploadUrl"
              :multiple="true"
              :data="{ subdir: this.subdirName }"
              :file-list="fileList"
              :showUploadList="{
                showPreviewIcon: true,
                showRemoveIcon: !isDisabled,
              }"
              @change="handleUploadChange"
            >
              <a-button v-if="!isDisabled"> <a-icon type="upload" /> 上传附件 </a-button>
            </a-upload>
          </a-form-item>
        </a-col>
      </a-row>

      <!-- <a-col :span="24">
        <a-form-item class="btn--container">
          <a-button type="button" @click="close()">
            {{ l("Cancel") }}
          </a-button>
          <a-button type="primary" html-type="submit">
            {{ l("Save") }}
          </a-button>
        </a-form-item>
      </a-col> -->
    </a-form>
    <div class="modal-footer" v-if="!isDisabled">
      <a-button :disabled="saving" type="button" @click="close()">
        {{ l("Cancel") }}
      </a-button>
      <a-button :disabled="saving" type="primary" @click="handleSubmit">
        {{ l("Save") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { ServiceProxy } from "./services/serivces-proxies";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";

export default {
  mixins: [ModalComponentBase],
  name: "create-or-edit-blogrolls",
  props: ["id", "type"],
  data() {
    return {
      // 获取到的数据
      entity: {},
      _serviceProxy: "",
      // 上传文件
      fileList: [],
      uploadUrl: "",
      subdirName: "",
      isDisabled: false,
      orgOption: [],
      customerOption: [],
    };
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: "register",
    });
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._serviceProxy = new ServiceProxy(this.$apiUrl, this.$api);
    // this.orgOption =  this._serviceProxy.getOrgOption();
    this.getOrgOption();
    // this.customerOption =  this._serviceProxy.getCustomerOption();
    this.getCustomerOption();
    this.uploadUrl = AppConsts.uploadBaseUrl + "/API/Upload";
    this.subdirName = environment.production ? "abpProd" : "abpDev";
    if (this.id) {
      this.getData();
    }
    this.isDisabled = this.type === "preview";
  },
  mounted() {},
  methods: {
    async getOrgOption(){
    this.orgOption = await this._serviceProxy.getOrgOption();
    },
    async getCustomerOption(){
    this.customerOption = await this._serviceProxy.getCustomerOption();

    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 上传组件 @change
    handleUploadChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = `${AppConsts.uploadBaseUrl}${file.response.data.Path}`;
        }
        return file;
      });
      this.fileList = fileList;
      console.log(this.fileList);
    },
    close() {
      this.$emit("close");
    },
    success(res) {
      this.$emit("success",res);
    },
    /**
     * 获取数据
     */
    async getData() {
      this.$emit("emitSpinning", "1");
      var res = await this._serviceProxy.getForEdit(this.id);
      this.$emit("emitSpinning", "0");
      console.log(res);
      this.form.setFieldsValue({
        ...res,
        evTime: [res.startTime, res.endTime],
        // id: this.id,
      });
      if (res.enclosure && res.enclosure.indexOf(",") !== -1) {
        let arr = res.enclosure.split(",");
        arr.map((item, i) => {
          let n = item.split("/");
          this.fileList.push({
            uid: `-${i + 1}`,
            url: `${AppConsts.uploadBaseUrl}${item}`,
            name: n[n.length - 1],
            response: { data: { Path: item } },
            status: "done",
          });
        });
      } else if (res.enclosure && res.enclosure !== 'undefined') {
        let n = res.enclosure.split("/");
        this.fileList.push({
          uid: `-1`,
          url: `${AppConsts.uploadBaseUrl}${res.enclosure}`,
          name: n[n.length - 1],
          response: { data: { Path: res.enclosure } },
          status: "done",
        });
      }
    },
    /**
     * 提交表单
     */
    async handleSubmit(e) {
      e.preventDefault();
      this.saving = true;
      let fileUrlList = [];
      this.fileList.map((item) => {
        fileUrlList.push(item.response.data.Path);
      });
      let attachmenResources =
        fileUrlList.length > 1 ? fileUrlList.join(",") : `${fileUrlList[0]}`;
      this.form.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            this.$emit("emitSpinning", "1");
            this.saving = false;

            let res = await this._serviceProxy.createOrUpdate({
              ...values,
              id: this.id ? this.id : null,
              enclosure: attachmenResources,
            });
            this.$emit("emitSpinning", "0");
            this.$notification["success"]({
              message: this.l("SavedSuccessfully"),
            });
            this.success(true);
          } catch {
            this.$emit("emitSpinning", "0");
            this.saving = false;
          }
        } else {
          this.saving = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
  display: block;
  // margin: 10px auto;
  text-align: right;
}

.modal-footer {
  padding: 10px 0;
  margin: 0;
}

.pleaseSelect-text {
  font-size: 14px;
  padding: 0 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0;
}
</style>
