import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// import {
//   GetOrganizationExtendPagedDTO,
//   GetOrganizationExtendByIdDTO,
//   IGetOrganizationExtendByIdDTO,
//   SaveOrganizationSealDTO,
//   SaveOrganizationBankAccountDTO,
// } from './model'

import { SwaggerException } from "@/shared/service-proxies/model";

import moment from 'moment';

export class OrganizationUnitsServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }


  protected processGetPaged(
    response: AxiosResponse
  ): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      let result200: any = null;
      let resultData200 = _responseText;
      // result200 = GetOrganizationExtendPagedDTO.fromJS(resultData200);
      return resultData200;
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }
  protected processGetItemById(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<any>(<any>null);
  }
  protected processSaveItem(response: AxiosResponse): Promise<any> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<void>(<any>null);
  }
  protected processDeleteItem(response: AxiosResponse): Promise<void> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return Promise.resolve<void>(<any>null);
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException(
        "An unexpected server error occurred.",
        status,
        _responseText,
        _headers
      );
    }
    return Promise.resolve<void>(<any>null);
  }

  /**
   * 获取银行账户(分页)
   * @param name 
   * @param idKey
   * @param id 
   * @param filterText 
   * @param sorting 
   * @param maxResultCount 
   * @param skipCount 
   */
  getAccountPaged(
    reqUrl: string,
    name: string | undefined,
    idKey: string,
    id: string | number,
    filterText: string | undefined,
    sorting: string | undefined,
    maxResultCount: string | undefined,
    skipCount: string | undefined,
  ): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/${reqUrl}?name=${name}&${idKey}=${id}&filterText=${filterText}&sorting=${sorting}&maxResultCount=${maxResultCount}&skipCount=${skipCount}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetPaged(_response);
    });
  }

  /**
   * 获取银行账户(单个)
   * @param id 
   */
  getAccountById(id: string | undefined, reqUrl: string): Promise<any> {
    let url_ = this.baseUrl + `/api/services/app/${reqUrl}?`;
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        Accept: "text/plain",
      },
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processGetItemById(_response);
    });
  }

  /**
   * 添加或编辑印章
   * @param body 
   */
  saveAccount(body: any | undefined, reqUrl: string): Promise<any> {
    console.log(reqUrl)
    let url_ = this.baseUrl + `/api/services/app/${reqUrl}`;
    url_ = url_.replace(/[?&]$/, "");

    const content_ = body;

    let options_ = <AxiosRequestConfig>{
      data: content_,
      method: "POST",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };
    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processSaveItem(_response);
    });
  }

  /**
   * 删除印章
   * @param id 
   */
  deleteAccount(id: number | string, reqUrl: string): Promise<void> {
    let url_ = this.baseUrl + `/api/services/app/${reqUrl}?`;
    if (id === null) {
      throw new Error("The parameter 'id' cannot be null.");
    } else if (id !== undefined) {
      url_ += "id=" + encodeURIComponent("" + id) + "&";
    }
    url_ = url_.replace(/[?&]$/, "");

    console.log(url_)

    let options_ = <AxiosRequestConfig>{
      method: "DELETE",
      url: url_,
      headers: {},
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processDeleteItem(_response);
    });
  }




}


function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}

