var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"form":_vm.form}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('签约客户')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'customerId',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'customerId',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","disabled":_vm.type=='edit'?true:_vm.isDisabled,"placeholder":_vm.l('请选择'),"filter-option":_vm.filterOption,"options":_vm.customerOption}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('签约公司')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'organizationUnitId',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'organizationUnitId',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"show-search":"","disabled":_vm.type=='edit'?true:_vm.isDisabled,"placeholder":_vm.l('请选择'),"filter-option":_vm.filterOption,"options":_vm.orgOption}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('合同名称')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'contractName',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'contractName',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('合同编码')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'code',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('合同类型')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'contractType',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'contractType',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请选择')}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 人力外包合同 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 咨询服务合同 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 融资租赁合同 ")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v(" 技术合同 ")]),_c('a-select-option',{attrs:{"value":5}},[_vm._v(" 新经济个体 ")])],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('签署日期')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'dateOfSigning',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'dateOfSigning',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('起始时间')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'startTime',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'startTime',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('结束时间')}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'endTime',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.l('validation.required'),
                  } ],
              } ]),expression:"[\n              'endTime',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: l('validation.required'),\n                  },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.isDisabled,"placeholder":_vm.l('请输入')}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.l('附件资源')}},[_c('a-upload',{attrs:{"action":_vm.uploadUrl,"multiple":true,"data":{ subdir: this.subdirName },"file-list":_vm.fileList,"showUploadList":{
              showPreviewIcon: true,
              showRemoveIcon: !_vm.isDisabled,
            }},on:{"change":_vm.handleUploadChange}},[(!_vm.isDisabled)?_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传附件 ")],1):_vm._e()],1)],1)],1)],1)],1),(!_vm.isDisabled)?_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"disabled":_vm.saving,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.l("Save"))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }