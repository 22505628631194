<template>
  <a-spin :spinning="spinning">
    <!-- <page-header :title="l('线下合同管理')"></page-header> -->
    <!-- <a-card :bordered="false"> -->
    <!-- <react-table
      :table-data="tableData"
      :total-items="totalItems"
      :showTotal="showTotalFun"
      :actions-type="actionsType"
      :columns="columns"
      :current-page="pageNumber"
      :hide-row-selection="true"
      :is-full="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSortData="updateSortData"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :actionsType="actionsType"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
    >
      <!-- 按钮 -->
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="filterText"
            :placeholder="l('请输入合同名称/合同编号')"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
              <a-select
                v-model="CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
              <a-select
                v-model="OrganizationUnitId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('请选择签约公司')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
        </a-col>
        <!-- <a-col class="gutter-row" :span="4">
          <a-input
            v-model.trim="signCompany"
            :placeholder="l('签约公司')"
          />
        </a-col> -->
                <!--日期范围 -->
        <a-col class="gutter-row" :span="4">
          <a-range-picker
            :placeholder="[l('起始日期'), l('结束日期')]"
            v-model="startToEndDate"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.LGModul.OfflineContract.Query')"
          >
            {{ l("Search") }} 
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>

        <a-col class="gutter-row" style="text-align: right" :span="4">
          <a-button
            type="primary"
            v-if="isGranted('Pages.LGModul.OfflineContract.Create')"
            @click="createOrEdit(null, 'create')"
          >
            <a-icon type="plus" />
            {{ l("Create") }}
          </a-button>
          <a-button v-if="isGranted('Pages.LGModul.OfflineContract.Export')" @click="exportToExcel()">
            <a-icon type="file-excel" />
            <span>{{ l("Export") }}</span>
          </a-button>
        </a-col>
      </a-row>
    </ele-table>
    <!-- </a-card> -->
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { ServiceProxy } from "./services/serivces-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import EditForm from "./edit";
import CreateEdit from "./create-edit/create-edit";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
// import { ListTable } from "@/components";
import EleTable from "@/components/ele-table";

export default {
  mixins: [AppComponentBase],
  name: "bannerads",
  components: { EleTable },
  data() {
    return {
      spinning: false,
      name: "",
      // 搜索
      filterText: "",
      signCompany: '',//签约公司
      // 选择多少项
      selectedRowKeys: [],
      tableData: [],
      tableKey: "ContractPagedDto",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 用户表格
      columns: [
        {
          title: this.l("合同名称"),
          dataIndex: "contractName",
          sorter: false,
          // ellipsis: true,
          fixed: "left",
          width: 200,
          // align: "center",
          scopedSlots: { customRender: "contractName" },
        },
        {
          title: this.l("合同编号"),
          dataIndex: "code",
          sorter: false,
          // ellipsis: true,
          fixed: "left",
          // align: "center",
          scopedSlots: { customRender: "contractName" },
        },
        {
          title: this.l("所属公司（客户）"),
          dataIndex: "customerName",
          sorter: false,
          // align: "center",
          width: 250,
          // ellipsis: true,
          scopedSlots: { customRender: "customerName" },
        },
        {
          title: this.l("所属签约公司（组织架构）"),
          dataIndex: "organizationUnitName",
          sorter: false,
          ellipsis: true,
          width: 250,
          // align: "center",
          scopedSlots: { customRender: "organizationUnitName" },
        },
        {
          title: this.l("合同类型"),
          dataIndex: "contractType",
          sorter: true,
          width: 150,
          // align: "center",
          scopedSlots: { customRender: "contractType" },
        },
        {
          title: this.l("签署日期"),
          dataIndex: "dateOfSigning",
          sorter: false,
          width: 120,
          // align: "center",
          scopedSlots: { customRender: "dateOfSigning" },
        },
        {
          title: this.l("起始日期"),
          dataIndex: "startTime",
          sorter: false,
          width: 120,
          // align: "center",
          scopedSlots: { customRender: "startTime" },
        },

        {
          title: this.l("结束日期"),
          dataIndex: "endTime",
          sorter: false,
          width: 120,
          // align: "center",
          scopedSlots: { customRender: "endTime" },
        },
        {
          title: this.l("创建时间"),
          dataIndex: "creationTime",
          sorter: true,
          ellipsis: true,
          width: "120px",
          // align: "center",
          scopedSlots: { customRender: "creationTime" },
        },

        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: false,
          // align: "center",
          fixed: "right",
          // width: "130px",
          scopedSlots: { customRender: "actions" }, //
        },
      ],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: {
        sorting: "",
        maxResultCount: 50,
        skipCount: 0,
      },
      _serviceProxy: "",
      commonService: null,
      actionsType: [],
        Customers: [],
        PayCompanys: [],
               CustomerId: undefined,
        OrganizationUnitId: undefined,
              startToEndDate: [],
    };
  },
  created() {
    this._serviceProxy = new ServiceProxy(this.$apiUrl, this.$api);
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.getData();
        this.getAllPayCompany();
    this.getAllCutomer();
    this.initActionsType();
  },
  methods: {
        filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
     var  startDate =!this.startToEndDate[0]? undefined : this.startToEndDate[0].format('YYYY-MM-DD HH:mm:ss');
     var  endDate = !this.startToEndDate[1]? undefined : this.startToEndDate[1].format('YYYY-MM-DD HH:mm:ss');
      var response = await this._serviceProxy.getPaged({
        startDate:startDate,
        endDate:endDate,
        name: this.filterText,
        filterText: this.filterText,
        signCompany: this.signCompany,
        customerId:this.CustomerId,
        organizationUnitId:this.OrganizationUnitId,
        sorting: this.request.sorting,
        maxResultCount: this.request.maxResultCount,
        skipCount: this.request.skipCount,
      });
      this.spinning = false;
      this.tableData = response.items;
      this.tableData.map((item) => {
        item.dateOfSigning = item.dateOfSigning
          ? moment(item.dateOfSigning).format("YYYY-MM-DD")
          : "-";
        item.endTime = item.endTime
          ? moment(item.endTime).format("YYYY-MM-DD")
          : "-";
        item.startTime = item.startTime
          ? moment(item.startTime).format("YYYY-MM-DD")
          : "-";
        item.creationTime = item.creationTime
          ? moment(item.creationTime).format("YYYY-MM-DD")
          : "-";
        item.contractType = this.getContractTypeDesc(item.contractType);
      });
      this.totalItems = response.totalCount;
      this.totalPages = Math.ceil(
        response.totalCount / this.request.maxResultCount
      );
      this.pagerange = [
        (this.pageNumber - 1) * this.request.maxResultCount + 1,
        this.pageNumber * this.request.maxResultCount,
      ];
    },
    getContractTypeDesc(t) {
      switch (t) {
        case 1:
          return "人力外包合同";
          break;
        case 2:
          return "咨询服务合同";
          break;
        case 3:
          return "融资租赁合同";
          break;
        case 4:
          return "技术合同";
          break;
        case 5:
          return "新经济个体";
        default:
          return "暂无";
      }
    },
    /**
     * table选择事件
     */
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * table选择事件
     */

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    /**
     * 分页
     */
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
          this.CustomerId = undefined;
      this.OrganizationUnitId = undefined;
          this.startToEndDate = [];
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.refreshGoFirstPage();
    },
    /**
     * 新建修改
     */
    createOrEdit(item, type) {
      ModalHelper.create(
        CreateEdit,
        {
          customer: {
            type: type, // preview, create时不显示tab, edit
            id: item ? item.id : "",
            shortName: item ? item.contractName : "",
          },
        },
        {
          width: "1000px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log(res);
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },

    /**
     * 清空
     */
    restCheckStatus() {
      this.selectedRowKeys = [];
    },
    /**
     * 单个删除
     */
    deleteItem(id) {
      this.spinning = true;
      this._serviceProxy
        .deleteCustomer(id)
        .finally(() => {
          this.spinning = false;
        })
        .then(() => {
          this.refreshGoFirstPage();
          this.$notification["success"]({
            message: this.l("SuccessfullyDeleted"),
          });
        });
    },
    /**
     * 导出为excel
     */
    exportToExcel() {
      this.spinning = true;
           var  startDate =!this.startToEndDate[0]? undefined : this.startToEndDate[0].format('YYYY-MM-DD HH:mm:ss');
     var  endDate = !this.startToEndDate[1]? undefined : this.startToEndDate[1].format('YYYY-MM-DD HH:mm:ss');
      let data = {
                startDate:startDate,
        endDate:endDate,
        name: this.filterText,
        filterText: this.filterText,
        signCompany: this.signCompany,
               customerId:this.CustomerId,
        organizationUnitId:this.OrganizationUnitId,
        sorting: this.request.sorting,
        maxResultCount: 50000,
        skipCount: 0,
      };
      this.$api.request({
          url: this.$apiUrl + '/api/services/app/Contract/Export',
          method: 'GET',
          params: data
      }).then((result) => {
        this.spinning = false;
        fileDownloadService.downloadTempFile(result.data);
      }).catch(e => {
        this.spinning = false;
        console.error(e);
      });
    },
    /*
     * 新建文章
     */
    newPost(id) {
      // this.$router.push({
      //     path: "/app/blogging/create-or-edit-posts",
      //     query: { blogId: id }
      // });
      ModalHelper.create(
        NewPostsComponent,
        {
          id: id,
        },
        {
          width: "400px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    // 预览
    previewItem(newV) {
      this.createOrEdit(newV.item, "preview");
    },
    /**
     * emit新建修改
     */
    editItem(newV) {
      // let { id } = newV.item;
      this.createOrEdit(newV.item, "edit");
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },

    /**
     * 初始化需要显示的func btn
     */
    // initActionsType() {
    //   let obj = {
    //     type: "Contract",
    //     isShow: true,
    //     fns: {
    //       check: { granted: true, name: this.l("查看") },
    //       edit: {
    //         granted: this.isGranted("Pages.Contract.Edit"),
    //         name: this.l("Edit"),
    //       },
    //       create: { granted: false, name: "" },
    //       delete: {
    //         granted: false,
    //         name: this.l("Delete"),
    //       },
    //     },
    //   };
    //   this.actionsType = obj;
    // },
    initActionsType() {
      let _this = this;
      let obj = {
        type: "CollectionForm",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.LGModul.OfflineContract.Detail"),
              name: this.l("查看"),
              icon: "table",
              fn: (data) => {
                _this.createOrEdit(data, "preview");
              },
            },
            {
              granted: this.isGranted("Pages.LGModul.OfflineContract.Edit"),
              name: this.l("Edit"),
              icon: "edit",
              fn: (data) => {
                _this.createOrEdit(data, "edit");
              },
            },
          ],
          delete: {
            granted: this.isGranted("Pages.LGModul.OfflineContract.Delete"),
            name: this.l("Delete"),
            fn: (data) => {
              _this.deleteItem(data.id);
            },
          },
        },
      };
      this.actionsType = obj;
    },
    searchData() {
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          // url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          url:
            this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
                // console.log(this.Customers)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  // margin-bottom: 20px;
}

.pagination {
  margin: 10px auto;
  text-align: right;
}

.table-plus,
.table-delete {
  margin-left: 10px;
}
</style>
